<template>
  <div class="guide-style">
    <div class="wrapper">
      <guide type="style" title="TOM STYLE by ME" image="re_guide_me.png">
        나만의 톰스타일 아이디어!<br />
        원하는 아이템을 직접 골라 AR로 체험해보세요!
      </guide>
      <div class="button" @click="goNext()">
        <span>확인</span>
      </div>
    </div>
    <div class="bottom">
      <img
        class="tom-greyhound"
        src="../assets/re_main_logo_tomgreyhound.png"
        alt="Tom Greyhound"
      />
    </div>
  </div>
</template>

<script>
import Guide from "@/components/Guide.vue";

export default {
  components: {
    Guide,
  },
  methods: {
    goNext() {
      this.$router.push("/style");
    },
  },
};
</script>

<style lang="scss" scoped>
.guide-style {
  line-height: 1;
  height: 100%;
  .wrapper {
    height: 305.3px;
    position: absolute;
    left: 0;
    right: 0;
    top: calc(50% - (305.3px / 2));
  }
  .button {
    margin: 0 auto;
    margin-top: 28px;
    width: 242px;
    height: 36px;
    font-family: "Noto Sans KR";
    font-size: 13px;
    line-height: 30px;
    cursor: pointer;
    border: 1px solid #000;
    border-radius: 3px;
    span {
      display: inline;
      vertical-align: middle;
    }
  }
  .bottom {
    position: fixed;
    width: 100%;
    bottom: 66px;
    .tom-greyhound {
      width: 113.2px;
    }
  }
}
</style>
